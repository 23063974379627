export enum ENV {
  DEV = 'development',
  PROD = 'production',
  STAG = 'staging',
}

export const APP_ENV =
  (process.env.REACT_APP_ENV as ENV.DEV | ENV.PROD | ENV.STAG) || ENV.DEV

export const ALCHEMY_KEY_MAP = {
  [ENV.DEV]: 'VfqdhLk5aQsqWTm62vblFSJRxdqay8un',
  [ENV.PROD]: 'AU0LYiWqcosWMPhtQcb8q4dlRYejyODC',
  [ENV.STAG]: 'VfqdhLk5aQsqWTm62vblFSJRxdqay8un',
}

export enum SupportedNetworks {
  Goerli = 5,
  Gnosis = 100,
  Sepolia = 11155111,
  ScrollSepolia = 534351,
}

export const SUPPORTED_NETWORKS = [
  SupportedNetworks.Goerli,
  SupportedNetworks.Gnosis,
  SupportedNetworks.Sepolia,
  SupportedNetworks.ScrollSepolia,
]

export const RELAYER_URL_MAP = {
  [SupportedNetworks.Goerli]:
    'https://api.defender.openzeppelin.com/autotasks/987313d2-c2e4-4f47-9502-9d80f1a0be6d/runs/webhook/c496af9a-9a28-4eb1-b732-bf83c0ec3f0e/2mRkqf9krbkhLYjpxzSgpR',
  [SupportedNetworks.Gnosis]:
    'https://p6u2zvdg5e.execute-api.eu-west-2.amazonaws.com/',
  [SupportedNetworks.Sepolia]:
    'https://5f4xjb3bdi.execute-api.eu-west-2.amazonaws.com/',
  [SupportedNetworks.ScrollSepolia]:
    'https://5f4xjb3bdi.execute-api.eu-west-2.amazonaws.com/',
}

export const RPC_URL_MAP = {
  [SupportedNetworks.Goerli]: `https://eth-goerli.alchemyapi.io/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`,
  [SupportedNetworks.Gnosis]:
    'https://rpc.ankr.com/gnosis/9ed50989d819f8036e2e8b77772fb50f074ef3d1fc6c3c289dbe3e192e25101d',
  [SupportedNetworks.Sepolia]: `https://eth-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`,
  [SupportedNetworks.ScrollSepolia]: 'https://sepolia-rpc.scroll.io',
}

export const PORTAl_CONFIG_MAP = {
  [SupportedNetworks.Goerli]: {
    rpcUrl: RPC_URL_MAP[SupportedNetworks.Goerli],
    relayerUrl: RELAYER_URL_MAP[SupportedNetworks.Goerli],
  },
  [SupportedNetworks.Gnosis]: {
    rpcUrl: RPC_URL_MAP[SupportedNetworks.Gnosis],
    relayerUrl: RELAYER_URL_MAP[SupportedNetworks.Gnosis],
  },
  [SupportedNetworks.Sepolia]: {
    rpcUrl: RPC_URL_MAP[SupportedNetworks.Sepolia],
    relayerUrl: RELAYER_URL_MAP[SupportedNetworks.Sepolia],
  },
  [SupportedNetworks.ScrollSepolia]: {
    rpcUrl: RPC_URL_MAP[SupportedNetworks.ScrollSepolia],
    relayerUrl: RELAYER_URL_MAP[SupportedNetworks.ScrollSepolia],
  },
}
