/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { Dispatch, useRef, useState } from 'react'
import NavbarHOC from '../HOC/NavbarHOC'
import { Like } from '../WebPages/Mint/Like'
import { DocStatus, SavingStatusUI } from '../ExcalidrawCanvas/SavingStatusUI'
import cn from 'classnames'
import { yellowBtn } from '../ExcalidrawCanvas/CanvasCustomUI'
import { useOutsideClick } from '../../hooks/useOutsideClickDetector'
import { WebPageBottomUI } from '../WebPages/WebPageBottomUI'
import { useMediaMax1025px } from '../../hooks/useMediaQueryHook'
import { useSettingSignal } from '../../store/portalUpdateLogs/hooks'
import { updateSettingSignal } from '../../store/portalUpdateLogs/reducer'
import { useDispatch } from 'react-redux'
import Logo from '../../assets/mainLogo.png'
import usePortalLogo from '../../hooks/usePortalLogo'
import PublishEdits from './PublishEdits'
import { ISEAPair } from 'gun'
import AccountTab from '../common/AccountTab'
import CollaboratorsList from './CollaboratorsList'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { Button } from '../../pages/PublicPortal/components/Button'
import {
  ChevronLeft,
  CircleX,
  ScanEye,
  Settings2,
  Users,
  MessageSquareText,
  Ellipsis,
} from 'lucide-react'
import { TextField } from '@fileverse/ui'
import { Tooltip } from '@mui/material'

const PluginNavbar = ({
  backButtonAction,
  isUserACollaborator,
  pluginTitle,
  setPluginTitle,
  portalName,
  pluginTool,
  onLiveCollaborationTrigger,
  docStatus,
  setPreviewMode,
  isPreviewMode,
  isEditMode = false,
  setEditMode,
  setIsEditCancelFlag,
  isPublishLoading,
  isCommentsEnabled,
  toggleCommentVisibility,
  toggleMoreMenuVisibility,
  commentCount,
  publishPlugin,
  dropdownRef,
  isPublished,
  isCommentsVisible,
  toggleQrModalVisibility,
  toggleProvenaceModalVisibility,
  isCollaborating,
  collaborators,
  onCancleClick,
  onEditClick,
  isLocked,
  createEditedFile,
  isDisabled,
  rtcId,
  rtcKey,
  isTitleFieldEnabled,
  dPageInfo,
  isArweave,
  collaborationDisabled,
  isWhiteboard,
  isFile,
}: {
  backButtonAction: () => void
  isUserACollaborator: boolean
  pluginTitle: string
  setPluginTitle: (title: string) => void
  portalName: string
  pluginTool: JSX.Element
  onLiveCollaborationTrigger: () => void
  isPublished: boolean
  docStatus: DocStatus
  setPreviewMode: Dispatch<React.SetStateAction<boolean>>
  isPreviewMode: boolean
  isEditMode: boolean
  isPublishLoading: boolean
  isCommentsEnabled: boolean
  toggleCommentVisibility: () => void
  toggleMoreMenuVisibility: () => void
  commentCount: number
  setIsEditCancelFlag: React.Dispatch<React.SetStateAction<boolean>>
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
  publishPlugin: () => void
  dropdownRef: React.RefObject<HTMLDivElement>
  isCommentsVisible: boolean
  toggleQrModalVisibility: React.Dispatch<React.SetStateAction<boolean>>
  toggleProvenaceModalVisibility: () => void
  isCollaborating: boolean
  collaborators: any
  onCancleClick?: () => void
  onEditClick?: () => void
  isLocked?: boolean
  createEditedFile?: () => File
  isDisabled?: boolean
  rtcId?: string
  rtcKey?: ISEAPair
  isTitleFieldEnabled: boolean
  dPageInfo?: {
    emoji: string
    coverIPFSHash: string
  }
  isArweave?: boolean
  collaborationDisabled?: boolean
  isWhiteboard?: boolean
  isFile?: boolean
}) => {
  const renderCenterUi = () => {
    if (!isPreviewMode) {
      if (isPublished) {
        if (isEditMode) {
          return pluginTool
        }
      } else {
        return pluginTool
      }
    }
  }
  const isMediaMax1025px = useMediaMax1025px()
  const sideMenuTogglerRef = useRef(null)
  const toggleMobileSideMenu = () => {
    if (!sideMenuTogglerRef?.current) return
    ;(sideMenuTogglerRef?.current as any).toggleSidemMenu()
  }
  return (
    <NavbarHOC
      sideMenuTogglerRef={sideMenuTogglerRef}
      leftSection={
        <PluginNavbarLeftSection
          portalName={portalName}
          setPluginTitle={setPluginTitle}
          backButtonAction={backButtonAction}
          isUserACollaborator={isUserACollaborator}
          pluginTitle={pluginTitle}
          isTitleFieldEnabled={isTitleFieldEnabled}
          filePreviewPage={isPublished}
          editMode={isEditMode}
        />
      }
      bottomSection={
        isMediaMax1025px && (
          <WebPageBottomUI
            filePreviewPage={isPublished}
            isCollaborator={isUserACollaborator}
            commentsEnabled={isCommentsEnabled}
            commentsAvailable={{
              available: !!commentCount,
              count: commentCount,
            }}
            toggleMobileSideMenu={toggleMobileSideMenu}
            showComments={isCommentsVisible}
            setIsQrVisible={toggleQrModalVisibility}
            setShowComments={toggleCommentVisibility}
            setProofPublishOpen={toggleProvenaceModalVisibility}
          />
        )
      }
      centerSection={renderCenterUi()}
      rightSection={
        <PluginNavbarRightSection
          isLocked={isLocked}
          onLiveCollaborationTrigger={onLiveCollaborationTrigger}
          docStatus={docStatus}
          setPreviewMode={setPreviewMode}
          isPreviewMode={isPreviewMode}
          isPublished={isPublished}
          isEditMode={isEditMode}
          setEditMode={setEditMode}
          setIsEditCancelFlag={setIsEditCancelFlag}
          isPublishLoading={isPublishLoading}
          isCommentsEnabled={isCommentsEnabled}
          toggleCommentVisibility={toggleCommentVisibility}
          toggleMoreMenuVisibility={toggleMoreMenuVisibility}
          commentCount={commentCount}
          isUserACollaborator={isUserACollaborator}
          publishPlugin={publishPlugin}
          dropdownRef={dropdownRef}
          collaborators={collaborators}
          isCollaborating={isCollaborating}
          onCancleClick={onCancleClick}
          onEditClick={onEditClick}
          createEditedFile={createEditedFile!}
          fileName={pluginTitle}
          isDisabled={isDisabled}
          rtcId={rtcId!}
          rtcKey={rtcKey}
          dPageInfo={dPageInfo}
          isArweave={isArweave}
          collaborationDisabled={collaborationDisabled}
          isWhiteboard={isWhiteboard}
          isFile={isFile}
        />
      }
    />
  )
}

export default PluginNavbar

export const PluginNavbarLeftSection = ({
  backButtonAction,
  isUserACollaborator,
  pluginTitle,
  setPluginTitle,
  portalName,
  isTitleFieldEnabled,
  filePreviewPage,
  editMode,
}: {
  backButtonAction: () => void
  isUserACollaborator: boolean
  pluginTitle: string
  setPluginTitle: (title: string) => void
  portalName: string
  isTitleFieldEnabled: boolean
  filePreviewPage: boolean
  editMode: boolean
}) => {
  const portalLogo = usePortalLogo()
  const isMediaMax1025px = useMediaMax1025px()

  return (
    <div className={cn('flex items-center w-full gap-4')}>
      <Button
        variant="ghost"
        onClick={backButtonAction}
        className="min-w-fit bg-transparent px-0"
      >
        {isUserACollaborator ? (
          <ChevronLeft size={20} />
        ) : (
          <img
            src={portalLogo || Logo}
            alt="Fileverse"
            className={cn(
              isMediaMax1025px ? 'w-8 h-8' : 'w-10 h-10',
              'object-cover rounded cursor-pointer'
            )}
          />
        )}
      </Button>
      <div className="w-full">
        {filePreviewPage ? (
          <>
            {editMode && isUserACollaborator ? (
              <TextField
                className="shadow-none w-full border-[1px]"
                placeholder="Untitled"
                defaultValue={pluginTitle}
                onChange={(e) => setPluginTitle(e.target.value)}
                disabled={!isTitleFieldEnabled}
                value={pluginTitle || ''}
              />
            ) : (
              <p
                className={cn(
                  'text-heading-sm-bold truncate',
                  isMediaMax1025px ? 'max-w-[10rem]' : 'max-w-[20rem]'
                )}
              >{`${portalName} / ${pluginTitle || 'untitled'}`}</p>
            )}
          </>
        ) : (
          <div className="flex items-center">
            {!isUserACollaborator ? (
              <>
                <p
                  className={cn(
                    'text-heading-sm-bold truncate',
                    isMediaMax1025px ? 'max-w-[10rem]' : 'max-w-[20rem]'
                  )}
                >{`${portalName} / ${pluginTitle || 'untitled'}`}</p>
              </>
            ) : (
              <TextField
                className="shadow-none w-full border-[1px]"
                placeholder="Untitled"
                defaultValue={pluginTitle}
                onChange={(e) => setPluginTitle(e.target.value)}
                value={pluginTitle || ''}
                disabled={!isTitleFieldEnabled}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export const PluginNavbarRightSection = ({
  onLiveCollaborationTrigger,
  docStatus,
  setPreviewMode,
  isPreviewMode,
  isPublished,
  isEditMode,
  setEditMode,
  setIsEditCancelFlag,
  isCommentsEnabled,
  toggleCommentVisibility,
  toggleMoreMenuVisibility,
  commentCount,
  isUserACollaborator,
  publishPlugin,
  dropdownRef,
  isCollaborating,
  collaborators,
  onCancleClick,
  onEditClick,
  isLocked,
  fileName,
  createEditedFile,
  isDisabled,
  rtcId,
  rtcKey,
  dPageInfo,
  isArweave,
  collaborationDisabled,
  isWhiteboard,
  isFile,
}: {
  onLiveCollaborationTrigger: () => void
  docStatus: DocStatus
  isPreviewMode: boolean
  setPreviewMode: React.Dispatch<React.SetStateAction<boolean>>
  isPublished: boolean
  isEditMode: boolean
  setIsEditCancelFlag: React.Dispatch<React.SetStateAction<boolean>>
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
  isPublishLoading: boolean
  isCommentsEnabled: boolean
  toggleCommentVisibility: () => void
  toggleMoreMenuVisibility: () => void
  commentCount: number
  isUserACollaborator: boolean
  publishPlugin: () => void
  dropdownRef: React.RefObject<HTMLDivElement>
  isCollaborating: boolean
  collaborators: any
  onCancleClick?: () => void
  onEditClick?: () => void
  isLocked?: boolean
  fileName?: string
  commentsEnabled?: boolean
  createEditedFile?: () => File
  isDisabled?: boolean
  rtcId?: string
  rtcKey?: ISEAPair
  dPageInfo?: {
    emoji: string
    coverIPFSHash: string
  }
  isArweave?: boolean
  collaborationDisabled?: boolean
  isWhiteboard?: boolean
  isFile?: boolean
}) => {
  const walletAddress = usePrivyHelper().walletAddress as string
  const disableSignal = useSettingSignal(walletAddress as string)
  const dispatch = useDispatch()
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const onDisconnectWallet = usePrivyHelper().onDisconnectWallet

  const accountButtonRef = useRef<HTMLDivElement>(null)
  const accountTabRef = useRef<HTMLDivElement>(null)
  useOutsideClick([accountButtonRef, accountTabRef], () =>
    setDropdownVisible(!isDropdownVisible)
  )

  const renderPreviewIcon = () => {
    return (
      <div id="right">
        <div className="p-3 h-16 overflow-y-hidden flex justify-center items-center gap-2">
          <Like mobileView={false} disabled={true} />
          <Tooltip
            classes={{
              tooltip: 'bg-black text-white',
              arrow: 'text-black',
            }}
            arrow
            placement="bottom"
            title={'Comments'}
          >
            <div className="flex justify-center items-center w-8 h-8 cursor-pointer hover:color-button-secondary-hover rounded">
              <MessageSquareText size={20} />
            </div>
          </Tooltip>
          {walletAddress && (
            <Tooltip
              classes={{
                tooltip: 'bg-black text-white',
                arrow: 'text-black',
              }}
              arrow
              placement="bottom"
              title="More actions"
            >
              <div className="flex justify-center items-center w-8 h-8 cursor-pointer hover:color-button-secondary-hover rounded">
                <Ellipsis size={20} />
              </div>
            </Tooltip>
          )}

          {!isLocked && (
            <Button
              onClick={() => setPreviewMode(false)}
              className="min-w-[80px] h-[36px]"
            >
              Back
            </Button>
          )}
        </div>
      </div>
    )
  }

  const moreMenuButtonRef = useRef<HTMLDivElement>(null)
  useOutsideClick([moreMenuButtonRef, dropdownRef], toggleMoreMenuVisibility)

  const renderPublishedPluginButtons = () => {
    return (
      <div id="right">
        <div className="p-3 h-16 overflow-y-hidden flex justify-center items-center gap-2 text-body-sm-bold">
          <Like mobileView={false} />
          <Tooltip
            classes={{
              tooltip: 'bg-black text-white',
              arrow: 'text-black',
            }}
            arrow
            placement="bottom"
            title={isCommentsEnabled ? 'Comments' : 'Comments not enabled'}
          >
            <div className={cn('flex gap-1 items-center')}>
              <MessageSquareText
                size={20}
                className={cn(
                  'cursor-pointer',
                  !isCommentsEnabled && 'cursor-not-allowed'
                )}
                onClick={() => isCommentsEnabled && toggleCommentVisibility()}
              />
              {isCommentsEnabled && commentCount}
            </div>
          </Tooltip>
          <div ref={moreMenuButtonRef}>
            <Tooltip
              classes={{
                tooltip: 'bg-black text-white',
                arrow: 'text-black',
              }}
              arrow
              placement="bottom"
              title="More actions"
            >
              <div className="flex justify-center items-center w-8 h-8 cursor-pointer hover:color-button-secondary-hover rounded">
                <Ellipsis size={20} onClick={toggleMoreMenuVisibility} />
              </div>
            </Tooltip>
          </div>

          {isUserACollaborator && (
            <Button
              onClick={() => {
                if (onEditClick) {
                  onEditClick()
                  return
                }
                setEditMode && setEditMode(true)
              }}
              className={cn('min-w-[80px]', {
                hidden: isArweave,
              })}
            >
              {isEditMode ? 'Publish' : 'Edit'}
            </Button>
          )}
        </div>
      </div>
    )
  }
  const buttonRef = useRef<HTMLDivElement>(null)
  useOutsideClick([buttonRef, dropdownRef], toggleMoreMenuVisibility)

  const renderUtilButtons = () => {
    return isPublished ? (
      renderPublishedPluginButtons()
    ) : (
      <div id="right" className={`relative`}>
        {isCollaborating && !isUserACollaborator ? (
          <div className="gap-2 items-center flex p-3 h-16">
            <CollaboratorsList collaborators={collaborators} />
            {!isEditMode && <SavingStatusUI docStatus={docStatus} />}
          </div>
        ) : (
          <div className="gap-2 items-center flex p-3 h-16">
            {isEditMode && (
              <Tooltip
                classes={{
                  tooltip: 'bg-black text-white',
                  arrow: 'text-black',
                }}
                arrow
                title="Cancel"
                placement="bottom"
              >
                <div className="flex justify-center items-center flex-1 w-8 h-8 cursor-pointer color-text-danger hover:color-button-secondary-hover rounded">
                  <CircleX
                    size={20}
                    onClick={() => {
                      if (onCancleClick) {
                        onCancleClick()
                        return
                      }
                      setEditMode && setEditMode(false)
                      setIsEditCancelFlag && setIsEditCancelFlag(true)
                    }}
                  />
                </div>
              </Tooltip>
            )}
            {!isFile && (
              <>
                {isCollaborating && (
                  <CollaboratorsList collaborators={collaborators} />
                )}
                <Tooltip
                  classes={{
                    tooltip: 'bg-black text-white',
                    arrow: 'text-black',
                  }}
                  arrow
                  title={
                    isWhiteboard && isEditMode
                      ? `Your changes are NOT getting saved, if you close the window without publishing you will lose your updates`
                      : 'Your changes are getting saved'
                  }
                  placement="bottom"
                >
                  <div className="relative">
                    <SavingStatusUI docStatus={docStatus} />
                    {isWhiteboard && isEditMode && (
                      <div
                        className={`absolute top-2 right-2 w-2.5 bg-red h-2.5 rounded-full`}
                      ></div>
                    )}
                  </div>
                </Tooltip>
                <Tooltip
                  classes={{
                    tooltip: 'bg-black text-white',
                    arrow: 'text-black',
                  }}
                  arrow
                  title={'Preview'}
                  placement="bottom"
                >
                  <div className="flex justify-center items-center w-8 h-8 cursor-pointer  hover:color-button-secondary-hover rounded">
                    <ScanEye size={20} onClick={() => setPreviewMode(true)} />
                  </div>
                </Tooltip>
              </>
            )}

            <Tooltip
              classes={{
                tooltip: 'bg-black text-white',
                arrow: 'text-black',
              }}
              arrow
              title="Settings"
              placement="bottom"
            >
              <div
                ref={buttonRef}
                className="flex justify-center items-center w-8 h-8 cursor-pointer relative hover:color-button-secondary-hover rounded"
              >
                <Settings2
                  size={20}
                  onClick={() => {
                    if (!disableSignal) {
                      dispatch(
                        updateSettingSignal({
                          walletAddress,
                          settingSignalState: true,
                        })
                      )
                    }
                    toggleMoreMenuVisibility()
                  }}
                />
                {!disableSignal && (
                  <div
                    className={`absolute top-2 right-2 w-2.5 bg-red h-2.5 rounded-full`}
                  ></div>
                )}
              </div>
            </Tooltip>

            {!isFile && (
              <Tooltip
                classes={{
                  tooltip: 'bg-black text-white',
                  arrow: 'text-black',
                }}
                arrow
                title={collaborationDisabled ? 'Coming soon' : 'Live Collab'}
                placement="bottom"
              >
                <div
                  onClick={onLiveCollaborationTrigger}
                  className={cn(
                    'flex justify-center relative items-center w-8 h-8 cursor-pointer hover:color-button-secondary-hover rounded',
                    isCollaborating && collaborators.length > 0 && yellowBtn
                  )}
                >
                  <Users size={20} />
                </div>
              </Tooltip>
            )}

            {isEditMode ? (
              <PublishEdits
                fileName={fileName!}
                commentsEnabled={isCommentsEnabled}
                rtcId={rtcId!}
                rtcKey={rtcKey!}
                setIsEditing={setEditMode}
                publishPlugin={createEditedFile!}
                isDisabled={isDisabled}
                dPageInfo={dPageInfo}
              />
            ) : (
              <Tooltip
                classes={{
                  tooltip: 'bg-black text-white',
                  arrow: 'text-black',
                }}
                arrow
                title="Publish online your final version & pick if it should be a Public or Private"
                placement="bottom"
              >
                <Button
                  onClick={publishPlugin}
                  className="min-w-[80px] h-[36px]"
                >
                  Publish
                </Button>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    )
  }
  return (
    <>
      {isPreviewMode ? renderPreviewIcon() : renderUtilButtons()}
      {isDropdownVisible && (
        <AccountTab
          isLocked={true}
          signerAddress={walletAddress}
          setAccountDropDown={setDropdownVisible}
          handleDisconnectWallet={onDisconnectWallet}
          width="w-[max-content]"
          right={'right-[35px]'}
          top={'top-[60px]'}
          accountRef={accountTabRef}
        />
      )}
    </>
  )
}
