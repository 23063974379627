/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import styles from './index.module.scss'
import {
  PanelsTopLeft,
  Database,
  PencilLine,
  FileText,
  Bot,
  MessageSquareText,
  Diamond,
  Notebook,
} from 'lucide-react'
import { useNavigate, useParams } from 'react-router-dom'
import uuid from 'react-uuid'
import { defaultNetwork } from '../../../../../config/network-config'
import cn from 'classnames'
import { useMediaMax1025px } from '../../../../../hooks/useMediaQueryHook'
import sendNotifcation from '../../../../../utils/notification'

export type PluginType =
  | 'whiteboard'
  | 'doc'
  | 'dpage'
  | 'permastorage'
  | 'aipowa'
  | 'mynotes'
  | 'chat'
  | 'nft'

const PLUGINS: any = {
  whiteboard: {
    title: 'dWhiteboard',
    desc: 'Seamless brainstorming canvas for your team and peers',
    icon: <PencilLine />,
  },
  doc: {
    title: 'dDocument',
    desc: 'Like ‘Google Docs’, but decentralized & encrypted',
    icon: <FileText />,
  },
  dpage: {
    title: 'dPage',
    desc: 'Your open-source & decentralized alternative to “Notion”',
    icon: <PanelsTopLeft />,
  },
  permastorage: {
    title: 'PermaStorage',
    desc: 'Pay once & store for 200 years with Arweave!',
    icon: <Database />,
    disabled: true,
  },
  aipowa: {
    title: 'Aipowa',
    desc: 'Coming Soon',
    icon: <Bot />,
    disabled: true,
  },
  mynotes: {
    title: 'My Notes',
    desc: 'Coming Soon',
    icon: <Notebook />,
    disabled: true,
  },
  chat: {
    title: 'Chat',
    desc: 'Coming Soon',
    icon: <MessageSquareText />,
    disabled: true,
  },
  nft: {
    title: 'NFT',
    desc: 'Coming Soon',
    icon: <Diamond />,
    disabled: true,
  },
}

interface PluginCardProps {
  type: PluginType
}

export const PluginCard = (props: PluginCardProps) => {
  const { type } = props
  const plugin = PLUGINS[type]
  const navigate = useNavigate()
  const { address: contractAddress } = useParams()
  const chainId = defaultNetwork.chainId
  const isDisabled = plugin.disabled || false
  const isMediaMax1025px = useMediaMax1025px()
  const navigateTo = (type: string) => {
    if (isDisabled) return
    if (isMediaMax1025px) {
      sendNotifcation(
        'Try the desktop version!',
        'Some Fileverse features are not available on mobile yet, try desktop for all the latest updates! ',
        'warning',
        10000
      )
      return
    }
    const rtcId = uuid()
    switch (type) {
      case 'whiteboard':
        navigate(`/${contractAddress}/whiteboard/${rtcId}?chainId=${chainId}`)
        break
      case 'doc':
        navigate(
          `/${contractAddress}/document/${rtcId}?chainId=${chainId}&version=2`
        )
        break
      case 'dpage':
        navigate(`/${contractAddress}/dPages?chainId=${chainId}`)
        break
      case 'permastorage':
        navigate(`/${contractAddress}/storage?chainId=${chainId}`)
        break
      default:
        break
    }
  }

  return (
    <div
      className={cn(
        'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
        styles.plugin_card,
        isDisabled && styles.disabled
      )}
      onClick={() => navigateTo(props.type)}
      tabIndex={0}
    >
      {plugin.icon}
      <div className="flex flex-col gap-[8px]">
        <div className="text-[14px] font-medium">{plugin.title}</div>
        <p className={styles.card_desc}>{plugin.desc}</p>
      </div>
    </div>
  )
}
